/* eslint-disable react-hooks/exhaustive-deps */
/*
 * @Author: Zhang Jiinghan jinghan@deeplang.ai
 * @Date: 2022-10-29 14:46:51
 * @LastEditors: Lemon.Feng 
 * @LastEditTime: 2023-07-20 10:38:19
 * @FilePath: /want_quotes_frontend-master/src/App.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import axios from 'axios';

import Admin from './admin/Admin';
import Main from './main/Main';

import './App.css';
import NewDialog from './components/new-dialog';
import MaterialDialog from './components/material-dialog';
import MobileDialog from './components/mobile-dialog';
import TalkNewProduct from './components/talk-new-product';

const feedbackDomain = window.location.origin;


function App(props) {
  const location = useLocation();

  const [showWarning, setShowWarning] = useState(false);
  const [showMobileWarning, setMobileShowWarning] = useState(false);
  const [showTalkNew, setShowTalkNew] = useState(false);
  const [showTalkNewVersion, setShowTalkNewVersion] = useState(false);
  const [feedbackText, setFeedbackText] = useState();
  const [feedbackContact, setFeedbackContact] = useState();
  const [feedbackOption, setFeedbackOption] = useState([]);
  const [selectedOption, setSelectedOption] = useState(true);
  const [pathname, setPathname] = useState('');
  const [showFeedbackSuccess, setShowFeedbackSuccess] = useState(false);
  const [showPhoneError, setShowPhoneError] = useState(false);
  const [deviceType, setDeviceType] = useState();
  const [showMaterialModal, setShowMaterialModal] = useState(false);

  useEffect(() => {
    const getUrlParam = (name) => {
      const params = location.search.substring(1).split('&');
      for (const param of params) {
        const paramName = param.split('=')[0];
        const paramValue = param.split('=')[1];
        if (name === paramName) {
          return paramValue;
        }
      }
      return false;
    };

    let device = '';
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) { //判断iPhone|iPad|iPod|iOS
      device = 'IOS';
      setDeviceType('IOS');
    } else if (/(Android)/i.test(navigator.userAgent)) { //判断Android
      device = 'Android';
      setDeviceType('Android');
    } else {
      device = 'PC';
      setDeviceType('PC');
    };
    setPathname(window.location.href);
    if (device === 'PC') {
      const value = getUrlParam('source') || localStorage.getItem('source') || sessionStorage.getItem('source');
      if (value && value === 'shenyandayi') {
        // 如果localStorage中未存储
        if(!localStorage.getItem('source')) {
          sessionStorage.setItem('source', 'shenyandayi');
        }
        // 从深言达意跳转回来，判断是否弹出新手引导
        if(getUrlParam('source')) {
          const isShowTalkNew = sessionStorage.getItem('isShowTalkNew') || localStorage.getItem('isShowTalkNew');
          if (!isShowTalkNew || isShowTalkNew === 'forever') {
            setShowTalkNew(true);
            // sessionStorage.setItem('isShowTalkNew', 'false');
          } else {
            // 判断是否需要弹出素材库弹窗
            if(!localStorage.getItem('materialShow') && !sessionStorage.getItem('materialShow')) {
              setShowMaterialModal(true);
            }
          }
        } else {
          // 判断是否需要弹出素材库弹窗
          if(!localStorage.getItem('materialShow') && !sessionStorage.getItem('materialShow')) {
            setShowMaterialModal(true);
          }
        }
      } else {
        axios.get(`${feedbackDomain}/log/batch`, {
          params: {
              eventId: '13223245',
              eventType: 'click',
              client: deviceType === 'PC' ? 'pc' : 'mobile',
              location: 'wantquotes'
          }
        });
        setShowWarning(true);
      }
    }
    else {
      const value = getUrlParam('source') || localStorage.getItem('source') || sessionStorage.getItem('source');
      if (value && value === 'shenyandayi') {
        sessionStorage.setItem('source', 'shenyandayi');
      }
      // else {
      //   axios.get(`${feedbackDomain}/log/batch`, {
      //     params: {
      //         eventId: '13223245',
      //         eventType: 'click',
      //         client: deviceType === 'PC' ? 'pc' : 'mobile',
      //         location: 'wantquotes'
      //     }
      //   });
      // }
      setMobileShowWarning(true);
    }
  }, [location.search])

  // 永不显示新版弹窗
  const closeDialogForever = () => {
    axios.get(`${feedbackDomain}/log/batch`, {
      params: {
          eventId: '25512225',
          eventType: 'click',
          client: deviceType === 'PC' ? 'pc' : 'mobile',
          location: 'wantquotes'
      }
    });
    setShowWarning(false);
    localStorage.setItem('source', 'shenyandayi');
    // 判断是否需要弹出新手引导
    const isShowTalkNew = localStorage.getItem('isShowTalkNew') || sessionStorage.getItem('isShowTalkNew');
    if (!isShowTalkNew) {
      setShowTalkNew(true);
    }
    // 点击不再提示后，下次进入页面也不会再弹出新手引导
    if(!localStorage.getItem('isShowTalkNew')) {
      localStorage.setItem('isShowTalkNew', 'forever');
    }
    // 判断是否需要弹出素材库弹窗
    if(!localStorage.getItem('materialShow') && !sessionStorage.getItem('materialShow')) {
      setShowMaterialModal(true);
    }
  }

  // 本次登录关闭新版弹窗
  const closeMobileDialog = () => {
    axios.get(`${feedbackDomain}/log/batch`, {
      params: {
          eventId: '22452422',
          eventType: 'click',
          client: deviceType === 'PC' ? 'pc' : 'mobile',
          location: 'wantquotes'
      }
    });
    setMobileShowWarning(false);
    sessionStorage.setItem('source', 'shenyandayi');
  }

  const closeMobileDialogForever = () => {
    axios.get(`${feedbackDomain}/log/batch`, {
      params: {
          eventId: '25512225',
          eventType: 'click',
          client: deviceType === 'PC' ? 'pc' : 'mobile',
          location: 'wantquotes'
      }
    });
    setMobileShowWarning(false);
    localStorage.setItem('source', 'shenyandayi');
  }

  // 本次登录不显示新版反馈
  const handleCloseNew = () => {
    setShowTalkNew(false);
    sessionStorage.setItem('isShowTalkNew', 'false');
  }

  // 永不显示新版反馈
  const handleCloseNewForever = () => {
    setShowTalkNew(false);
    localStorage.setItem('isShowTalkNew', 'false');
  }

  // 本次登录关闭素材库导流弹窗
  const handleCloseMaterialDialog = () => {
    setShowMaterialModal(false);
    sessionStorage.setItem('materialShow', 'hidden');
  }

  const handleCloseMaterialDialogForever = () => {
    setShowMaterialModal(false);
    localStorage.setItem('materialShow', 'hidden');
  }

  // 输入新版建议
  const handleInputFeedbackText = (e) => {
    const text = e.target.value;
    setFeedbackText(text);
    if (text === '' && feedbackOption.length === 0) {
      setSelectedOption(true);
    }
    else {
      setSelectedOption(false);
    }
  }

  // 输入联系方式
  const handleInputFeedbackContact = (e) => {
    setFeedbackContact(e.target.value);
  }

  // 提价新版建议
  const handleSubmitFeedback = () => {
    if (feedbackContact && !(/^1[3456789]\d{9}$/.test(feedbackContact))) {
      setShowPhoneError(true);
      setTimeout(() => {
        setShowPhoneError(false);
      }, 3000)
      return false;
    }
    const unionid = localStorage.getItem('unionid');
    closeFeedback();
    handleCloseNew();
    axios.post(feedbackDomain + '/api/v1/users/feedback', {
      label: feedbackOption,
      content: feedbackText ? feedbackText : '',
      phone: feedbackContact,
      unionid: unionid ? unionid : ''
    })
      .then(res => {
        console.log('反馈成功');
        setShowFeedbackSuccess(true);
        setTimeout(() => {
          setShowFeedbackSuccess(false);
        }, 3000)
      })
      .catch(err => {
        console.error(err);
      })
  }

  // 关闭新版建议
  const closeFeedback = () => {
    setFeedbackText();
    setFeedbackContact();
    setFeedbackOption([]);
    setSelectedOption(true);
    setShowTalkNewVersion(false);
  }

  // 体验新版
  const handleExperienceNewVversion = () => {
    axios.get(`${feedbackDomain}/log/batch`, {
      params: {
          eventId: '45514314',
          eventType: 'click',
          client: deviceType === 'PC' ? 'pc' : 'mobile',
          location: 'wantquotes'
      }
    });
    if(pathname.includes('qa.')) {
      window.open('http://qa.shenyandayi.com', '_blank');
    }
    else if(pathname.includes('pre.')) {
      window.open('http://pre.shenyandayi.com', '_blank');
    }
    else {
      window.open('https://www.shenyandayi.com', '_blank');
    }
  }

  const handleSelectCurrentOption = (value) => {
    const index = feedbackOption.indexOf(value);
    if (index === -1) {
      feedbackOption.push(value);
    }
    else {
      feedbackOption.splice(index, 1);
    }
    console.log(feedbackOption);
    setFeedbackOption([...feedbackOption]);
    if (feedbackOption.length === 0 && (!feedbackText || feedbackText === '')) {
      setSelectedOption(true);
    }
    else {
      setSelectedOption(false);
    }
  }

  // 去探索
  const handleJumpshenyandayi = () => {
    // to={pathname.includes('qa.') ? 'http://qa.shenyandayi.com' : 'https://www.shenyandayi.com'}
    axios.get(`${feedbackDomain}/log/batch`, {
      params: {
          eventId: '12153432',
          eventType: 'perf',
          client: deviceType === 'PC' ? 'pc' : 'mobile',
          location: 'wantquotes'
      }
    });
    setShowWarning(false);
    sessionStorage.setItem('source', 'shenyandayi');
    const unionid = localStorage.getItem('unionid');
    const timestamp = (new Date()).getTime();
    console.log(timestamp);
    axios.post(feedbackDomain + '/api/v1/logs/batch', {
      unionid: unionid ? unionid : '',
      timestamp,
      is_click: true,
    })
      .then(res => {
        if (pathname.includes('qa.')) {
          window.open('http://qa.shenyandayi.com', '_blank');
        }
        else if (pathname.includes('pre.')) {
          window.open('http://pre.shenyandayi.com', '_blank');
        }
        else {
          window.open('https://www.shenyandayi.com', '_blank');
        }
      })
      .catch(err => {
        console.error(err);
      })
  }

  return (
    <div className='app'>
      <div onClick={handleCloseNew}>
        <Routes>
          <Route path='/' element={<Main test={false} />} />
          <Route path='/test' element={<Main test={true} />} />
          <Route path='/admin' element={<Admin />} />
        </Routes>
      </div>
      {deviceType === 'PC' && <div>
        <NewDialog showWarning={showWarning} closeDialogForever={closeDialogForever} handleJumpshenyandayi={handleJumpshenyandayi} />
        <MaterialDialog  open={showMaterialModal} closeDialog={handleCloseMaterialDialog} closeDialogForever={handleCloseMaterialDialogForever} handleJumpshenyandayi={handleJumpshenyandayi} />
        <div className='home-side'>
          <div className='experience-new-version' onClick={handleExperienceNewVversion}>
            <i className='iconfont icon-new' />
            <p>体验新版</p>
          </div>
          <div className='talk-new-version' onClick={() => {
            setShowTalkNewVersion(true);
            handleCloseNew();
          }}>
            <i className='iconfont icon-Message' />
            <p>新版反馈</p>
          </div>
          {
            showTalkNew && <div className='talk-about-new-version'>
              <span>关于新版有什么想说的吗？和我们聊聊吧🥺</span>
              <button className='close-talk' onClick={handleCloseNewForever}>不再提示</button>
              <i className='iconfont icon-Arrow-Wrapper' />
            </div>
          }
        </div>
        <TalkNewProduct 
          showTalkNewVersion={showTalkNewVersion}
          feedbackText={feedbackText}
          selectedOption={selectedOption}
          feedbackOption={feedbackOption}
          feedbackContact={feedbackContact}
          showPhoneError={showPhoneError}
          closeFeedback={closeFeedback}
          handleSelectCurrentOption={handleSelectCurrentOption}
          handleInputFeedbackText={handleInputFeedbackText}
          handleInputFeedbackContact={handleInputFeedbackContact}
          handleSubmitFeedback={handleSubmitFeedback}
        />
        {
          showFeedbackSuccess && <div className="feedback-success">
            <i className="iconfont icon-CheckCircle"></i>
            谢谢反馈，我们将努力做得更好
          </div>
        }

      </div>}
      {deviceType !== 'PC' && <MobileDialog open={showMobileWarning} closeMobileDialog={closeMobileDialog} closeMobileDialogForever={closeMobileDialogForever} />}
    </div>
  );
}

export default App;
