import React from 'react';
import {
  Dialog
} from "@mui/material";

function MobileDialog(props) {
  const { open, closeMobileDialog } = props;

  const isWeChat = () => {
    //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
    var ua = window.navigator.userAgent.toLowerCase();
    //通过正则表达式匹配ua中是否含有MicroMessenger字符串
    if( ua.indexOf('micromessenger') > -1){
      return true;
    } else{
      return false;
    }
  };

  const handleToMiniProgram = () => {
    const channel = isWeChat() ? 'wantquotes_h5' : 'wantquotes_pc_mobile';
    window.location.href = `weixin://dl/business/?appid=wx242a7feec2f33cfb&env_version=release&path=packageA/pages/welcome/index&query=channel=${channel}&business=wantquotes`
  }

  return (
    <Dialog
      open={open}
      maxWidth='320px'
      className='mobile-dialog'
    >
      <div className="mobile-body">
        <div className="mobile-container">
          <img className="content-logo" src="https://deeplang-frontend.oss-cn-zhangjiakou.aliyuncs.com/mini-program/bg-imgs/deep_logo.png" alt="logo" />
          <h1 className="mobile-title">小程序，全新上线</h1>
          <img className="mobile-slogan" src="https://deeplang-frontend.oss-cn-zhangjiakou.aliyuncs.com/mini-program/bg-imgs/deep_slogan.png" alt='深言达意' />
          <p className="mobile-content">
            <span className='text-dashed'>我们的小程序终于跟大家见面了，新产品继承了</span><br />
            <span className='text-dashed'>WantQuotes 的全部功能并进行了体验升级 🎉</span><br />
            <span className='text-dashed'>支持「<span className="text-blue">同时查词查句</span>」「<span className="text-blue">收藏</span>」词句并「<span className="text-blue">分享</span>」</span><br />
            {isWeChat() && (<><span className='text-dashed'>彻底解决经常登录失败的烦恼。</span><br /></>)}
            <span className='text-dashed'>希望大家玩得开心 🥳</span>
          </p>
          <button className="mobile-know" onClick={handleToMiniProgram}>
            <p>立即体验</p>
          </button>
        </div>
      </div>
      {
        !isWeChat() && (
          <div className='dialog-mobile-close' onClick={closeMobileDialog}>
            <i className='iconfont icon-close'></i>
          </div>
        )
      }
    </Dialog>
  )
}

export default MobileDialog