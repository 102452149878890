import * as React from "react";
import ReactDOM from 'react-dom';
import {
    Box, Card, CardHeader,
    Grid,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import axios from "axios";
import {domain} from "../main/Utils";
import './Admin.css';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReactApexChart from 'react-apexcharts';


function QueryTable(props) {

    if(props.rows.length > 0 && 'mistake_types' in props.rows[0]){

        for (let i = 0; i < props.rows.length; i++) {
            props.rows[i].quote.author = props.rows[i].quote.author||''
            props.rows[i].quote.work = props.rows[i].quote.work||''
            props.rows[i].quote.definition = props.rows[i].quote.definition||''
        }
    }
    return (
        <TableContainer component={Paper}>
            <Table size="small" className="kai">
                <TableHead>
                    <TableRow>
                        <TableCell align="center" className="x" sx={{width: '6%', m: 0, p: 1}}>id</TableCell>
                        <TableCell align="center" className="kai-x" sx={{width: '12%', m: 0, p: 0}}>时间</TableCell>
                        <TableCell align="center" className="x" sx={{width: '14%', m: 0, p: 0}}>unionid</TableCell>
                        {props.rows.length > 0 && !('suggestion' in props.rows[0]) &&
                            <TableCell align="center" className="kai-x" sx={{width: '10%', m: 0, p: 0}}>类型</TableCell>
                        }
                        {props.rows.length > 0 && 'suggestion' in props.rows[0] &&
                            <>
                                <TableCell align="center" className="kai-x" sx={{m: 0, p: 0}}>意见</TableCell>
                                <TableCell align="center" className="kai-x" sx={{m: 0, p: 0}}>联系方式</TableCell>
                            </>
                        }
                        {props.rows.length > 0 && 'query' in props.rows[0] &&
                            <>
                                <TableCell align="center" className="kai-x" sx={{m: 0, p: 0}}>查询</TableCell>
                                {'good_quote' in props.rows[0] &&
                                    <TableCell align="center" className="kai-x" sx={{m: 0, p: 0}}>点赞名句</TableCell>
                                }
                                {'good_quotes' in props.rows[0] &&
                                    <TableCell align="center" className="kai-x" sx={{m: 0, p: 0}}>标注</TableCell>
                                }
                                {'action' in props.rows[0] &&
                                    <>
                                        <TableCell align="center" className="kai-x" sx={{m: 0, p: 0}}>被操作名句</TableCell>
                                        <TableCell align="center" className="kai-x"
                                                   sx={{width: '6%', m: 0, p: 0}}>行为</TableCell>
                                    </>
                                }
                            </>
                        }
                        {props.rows.length > 0 && 'mistake_types' in props.rows[0] &&
                            <>
                                <TableCell align="center" className="kai-x" sx={{m: 0, p: 0}}>名句</TableCell>
                                <TableCell align="center" className="kai-x" sx={{m: 0, p: 0}}>错误类型</TableCell>
                                <TableCell align="center" className="kai-x" sx={{m: 0, p: 0}}>错误描述</TableCell>
                            </>
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.rows.map((row, i) => (
                        <TableRow key={i}>
                            <TableCell align="center" className="x" sx={{m: 0, p: 0}}>{row.pk}</TableCell>
                            <TableCell align="center" className="x" sx={{m: 0, p: 0}}>
                                {row.time.split('.')[0].replace('T', ' ')}
                            </TableCell>
                            <TableCell align="center" className="x" sx={{m: 0, p: 0}}>{row.unionid}</TableCell>
                            {props.rows.length > 0 && !('suggestion' in props.rows[0]) &&
                                <TableCell align="center" className="kai-x" sx={{m: 0, p: 0}}>
                                    {row.type}
                                </TableCell>
                            }
                            {props.rows.length > 0 && 'suggestion' in props.rows[0] &&
                                <>
                                    <TableCell align="center" className="kai-x"
                                               sx={{m: 0, p: 0}}>{row.suggestion}</TableCell>
                                    <TableCell align="center" className="kai-x"
                                               sx={{m: 0, p: 0}}>{row.contact}</TableCell>
                                </>
                            }
                            {props.rows.length > 0 && 'query' in props.rows[0] &&
                                <>
                                    <TableCell align="center" className="kai-x"
                                               sx={{m: 0, p: 0}}>{row.query}</TableCell>
                                    {'good_quote' in row &&
                                        <TableCell align="center" sx={{m: 0, p: 0}} className="kai-x">
                                            <div key={i}>
                                                <Tooltip title={"id:" + row.good_quote['id']} placement="right">
                                                    <span>{row.good_quote['quote']}</span>
                                                </Tooltip>
                                            </div>
                                        </TableCell>
                                    }
                                    {'good_quotes' in row &&
                                        <TableCell align="left" sx={{m: 0, p: 0}}>
                                            <Stack direction="column" spacing={1} sx={{m: 1}}>
                                                <Paper>
                                                    <Stack direction="row" spacing={1}>
                                                        <CheckCircleIcon color="success"/>
                                                        <Grid>
                                                            {row.good_quotes.map((quote, i) => (
                                                                <div key={i}>
                                                                    <Tooltip title={"id:" + quote['id']}
                                                                             placement="right">
                                                                        <span className="kai-x">{quote['quote']}</span>
                                                                    </Tooltip>
                                                                </div>
                                                            ))}
                                                        </Grid>
                                                    </Stack>
                                                </Paper>
                                                <Paper>
                                                    <Stack direction="row">
                                                        <CancelIcon color="warning"/>
                                                        <Grid>
                                                            {row.bad_quotes.map((quote, i) => (
                                                                <div key={i}>
                                                                    <Tooltip title={"id:" + quote['id']}
                                                                             placement="right">
                                                                        <span className="kai-x">{quote['quote']}</span>
                                                                    </Tooltip>
                                                                </div>
                                                            ))}
                                                        </Grid>
                                                    </Stack>
                                                </Paper>
                                            </Stack>

                                        </TableCell>
                                    }
                                    {'action' in row &&
                                        <>
                                            <TableCell align="center" className="kai-x"
                                                       sx={{m: 0, p: 0}}>{row.quote['quote']}</TableCell>
                                            <TableCell align="center" className="kai-x" sx={{m: 0, p: 0}}>
                                                <button className="mistake-type-label" disabled>
                                                    {{
                                                        'copy': '复制',
                                                        'relate': '相关句',
                                                        'baidu': '百度'
                                                    }[row.action]}
                                                </button>
                                            </TableCell>
                                        </>
                                    }
                                </>
                            }
                            {props.rows.length > 0 && 'mistake_types' in props.rows[0] &&
                                <>
                                    <TableCell align="left" className="" sx={{m: 0, p: 0}}>
                                        <div>
                                            <p className="quote-text">{row.quote.quote}</p>
                                            {row.quote.definition !== '' && <p className="def">{row.quote.def}</p>}
                                            <span className="kai-x">
                                                {(row.quote.author !== '' || row.quote.work !== '') &&
                                                    <hr className="dash"/>}
                                                {row.quote.author}
                                                {row.quote.work !== '' && <span>{'《' + row.quote.work + '》'}</span>}
                                            </span>
                                        </div>
                                    </TableCell>
                                    <TableCell align="center" className="kai-x"
                                               sx={{m: 0, p: 0}}>
                                        {row.mistake_types.map((type, i) => (
                                            <div key={i}>
                                                <button className="mistake-type-label" disabled>
                                                    {{
                                                        'quote': '名言',
                                                        'author': '作者',
                                                        'work': '作品',
                                                        'def': '译文',
                                                        'type': '分类',
                                                        'not': '不是名句',
                                                        'others': '其他',
                                                        'sensitive':'敏感'
                                                    }[type]}
                                                </button>
                                            </div>
                                        ))}
                                    </TableCell>
                                    <TableCell align="center" className="kai-x"
                                               sx={{m: 0, p: 0}}>{row.mistake_desc}</TableCell>
                                </>
                            }
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}


function CountTable(props) {
    let q_heads = ['全部时间', '24小时内', '1小时内']
    let q_counts = [
        props.counts['total_queries'],
        props.counts['24h_queries'],
        props.counts['1h_queries']
    ]
    let visitors = [
        props.counts['visitors']['total'],
        props.counts['visitors']['24h'],
        props.counts['visitors']['1h']
    ]
    return (
        <TableContainer component={Paper}>
            <Table size="small" className="kai">
                <TableHead>
                    <TableRow>
                        <TableCell align="center" className="x" sx={{m: 0, p: 0}}> </TableCell>
                        <TableCell align="center" className="kai-x" sx={{m: 0, p: 0}}>总查询量</TableCell>
                        <TableCell align="center" className="kai-x" sx={{width: '20%', m: 0, p: 0}}>现代文查询量</TableCell>
                        <TableCell align="center" className="kai-x" sx={{width: '20%', m: 0, p: 0}}>古诗文查询量</TableCell>
                        <TableCell align="center" className="kai-x" sx={{m: 0, p: 0}}>访客数</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {q_counts.map((q_count, i) => (
                        <TableRow key={i}>
                            <TableCell align="center" className="kai-x" sx={{m: 0, p: 0}}>{q_heads[i]}</TableCell>
                            <TableCell align="center" className="x" sx={{m: 0, p: 0}}>{q_count['all']}</TableCell>
                            <TableCell align="center" className="x" sx={{m: 0, p: 0}}>{q_count['quote']}</TableCell>
                            <TableCell align="center" className="x" sx={{m: 0, p: 0}}>{q_count['ancient']}</TableCell>
                            <TableCell align="center" className="x" sx={{m: 0, p: 0}}>{visitors[i]}</TableCell>
                        </TableRow>
                    ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}


function Chart(props) {
    let CHART_DATA = [
        {
            name: '现代汉语',
            type: 'column',
            data: props.day_queries['现']
        },
        {
            name: '古诗文',
            type: 'column',
            data: props.day_queries['古']
        },
        {
            name: '谚语',
            type: 'column',
            data: props.day_queries['谚']
        },
        {
            name: '歇后语',
            type: 'column',
            data: props.day_queries['歇']
        },
        {
            name: '访客数',
            type: 'line',
            data: props.day_queries['visitor']
        },
    ];

    let dates = [];
    let today = new Date();
    for (let i = 14; i >= 0; i--) {
        let date = new Date();
        date.setDate(today.getDate() - i);
        dates.push((date.getMonth() + 1) + '-' + date.getDate());
    }
    const chartOptions = {
        chart: {
            stacked: true,
            toolbar: {show: false},
            zoom: {enabled: false},
        },
        xaxis: {
            categories: dates
        },
        dataLabels: {
            enabled: true,

            enabledOnSeries: [3],
            textAnchor: "middle",
            offsetY: -10,
            formatter: function (_val, {seriesIndex, dataPointIndex, w}) {
                let series = CHART_DATA.slice(0,4)
                if (seriesIndex === 3) {
                    let idx = dataPointIndex
                    const total = series.reduce((total, self) => total + self.data[idx], 0)
                    return total + ''
                } else {
                    return _val
                }
            },
            background: {
                enabled: false,
            },
            style: {
                colors: ["#8d8d8d"]
            }
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    hideOverflowingLabels: false
                }
            }
        }
    };
    return (<Card>
        <CardHeader title={<span className='table-title'>每日查询量</span>}
                    subheader={<span className='kai-x'>最近15天</span>}/>
        <Box>
            {/*<ReactApexChart series={CHART_DATA} options={chartOptions} height={364}/>*/}
            <ReactApexChart series={CHART_DATA} options={chartOptions} height={364}/>
        </Box>
    </Card>)
}


export default function Admin() {

    axios.get(domain + 'statistics/', {}).then(response => {
        let stat = response.data;
        ReactDOM.render(<>
                <Chart day_queries={stat['counts']['day_queries']}/>
            </>,
            document.getElementById("counts-chart")
        )
        ReactDOM.render(<>
                <p className='table-title'>查询数量统计</p>
                <CountTable counts={stat['counts']}/>
            </>,
            document.getElementById("counts-table")
        )
        ReactDOM.render(<>
                <p className='table-title'>查询内容</p>
                <QueryTable rows={stat['queries']}/>
            </>,
            document.getElementById("queries-table")
        )
        ReactDOM.render(<>
                <p className='table-title'>点赞内容</p>
                <QueryTable rows={stat['likes']}/>
            </>,
            document.getElementById("likes-table")
        )
        ReactDOM.render(<>
                <p className='table-title'>用户行为</p>
                <QueryTable rows={stat['actions']}/>
            </>,
            document.getElementById("actions-table")
        )
        ReactDOM.render(<>
                <p className='table-title'>错误反馈内容</p>
                <QueryTable rows={stat['mistakes']}/>
            </>,
            document.getElementById("mistakes-table")
        )
        ReactDOM.render(<>
                <p className='table-title'>标注内容</p>
                <QueryTable rows={stat['annotations']}/>
            </>,
            document.getElementById("annotations-table")
        )
        ReactDOM.render(<>
                <p className='table-title'>意见反馈</p>
                <QueryTable rows={stat['suggestions']}/>
            </>,
            document.getElementById("suggestions-table")
        )
    })

    return (
        <Box sx={{minHeight: '100vh', bgcolor: '#eaeff1', pl: '20%', pr: '20%', pt: 2, pb: 2}}>
            <Stack spacing={2}>
                <Grid id="counts-chart" sx={{width: '100%'}}>

                </Grid>
                <Grid id="counts-table" sx={{width: '100%'}}>

                </Grid>
                <Grid id="queries-table" sx={{width: '100%'}}>

                </Grid>
                <Grid id="likes-table" sx={{width: '100%'}}>

                </Grid>
                <Grid id="actions-table" sx={{width: '100%'}}>

                </Grid>
                <Grid id="mistakes-table" sx={{width: '100%'}}>

                </Grid>
                <Grid id="annotations-table" sx={{width: '100%'}}>

                </Grid>
                <Grid id="suggestions-table" sx={{width: '100%'}}>

                </Grid>
            </Stack>
        </Box>
    );
}