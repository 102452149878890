import React from 'react';
import {
  Button,
  Dialog,
  DialogActions
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import new_image from '../../main/img/dialog_img.png';

function MaterialDialog(props) {
  const { open, closeDialog, closeDialogForever, handleJumpshenyandayi } = props;

  return (
    <Dialog
      open={open}
      maxWidth='620px'
      className='pc-mobile'
    >
      <DialogActions
        style={{ paddingTop: 12, paddingRight: 16, zIndex: 10 }}
      >
        <CloseIcon
          onClick={closeDialog}
          style={{ width: 18, height: 18, color: '#7A7B7B', cursor: "pointer" }}
        />
      </DialogActions>
      <div className='dialog-container'>
        <img src={new_image} className='dialog-image' alt='longowhale' />
        <div className='dialog-box'>
          <h2 className='dialog-title'>新功能【素材库】全新上线&nbsp;🎉</h2>
          <div className='dialog-content'>
            <p>响应大家的强烈呼声，我们的新功能<span className='lingo-whale'>「素材库」</span>上线啦。</p>
            <p>此次更新的核心功能为<span className='lingo-whale'>「输入词或词组找例句」</span>，可以帮大家找《人民日报》、“学习强国”等权威来源的高质量公文例句。同时，我们还收录了一批优质的公文对偶句和排比句，大家快去试一试吧！</p>
            <p>在产品使用中遇到任何问题，欢迎随时和我们反馈交流。积极反馈的伙伴有机会成为<span className='lingo-whale'>「深言达意产品共创官」</span>，持续与我们共同成长，期待你来！😄</p>
          </div>
        </div>
      </div>
      <Button className='dialog-btn' style={{ backgroundColor: '#2D2D2D', margin: '0 auto' }} onClick={handleJumpshenyandayi}>
        去探索
      </Button>
      <Button className='close-btn' style={{ margin: '0 auto' }} onClick={closeDialogForever}>不再提示</Button>

    </Dialog>
  )
}

export default MaterialDialog