import React from 'react';
import {
  Dialog,
  DialogActions
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const feedbacks = [
  { value: '不感兴趣', label: '不感兴趣' },
  { value: '新版不够简洁', label: '新版不够简洁' },
  { value: '用不到新功能', label: '用不到新功能' },
  { value: '不想注册', label: '不想注册' },
  { value: '对搜索结果不满意', label: '对搜索结果不满意' },
  { value: '我很念旧', label: '我很念旧' },
];

function TalkNewProduct(props) {
  const { showTalkNewVersion, feedbackText, feedbackOption, selectedOption, feedbackContact, showPhoneError, closeFeedback, handleSelectCurrentOption, handleInputFeedbackText, handleInputFeedbackContact, handleSubmitFeedback } = props;

  return (
    <Dialog open={showTalkNewVersion}>
      <DialogActions
        style={{ padding: '8px 16px', borderRadius: '2px', cursor: "pointer" }}
      >
        <CloseIcon
          onClick={closeFeedback}
          style={{ width: 18, height: 18, color: '#7A7B7B' }}
        />
      </DialogActions>
      <div className='new-container'>
        <title className='new-title'>和我们聊聊深言达意吧🥺</title>
        <ul className='new-feedback-list'>
          {
            feedbacks.map(item => <li className={`new-feedback-item ${feedbackOption.includes(item.value) ? 'new-feedback-item-active' : ''}`} onClick={() => handleSelectCurrentOption(item.value)} key={item.value}>{item.label}</li>)
          }
        </ul>
        <textarea className='new-feedback-text' placeholder='展开说说🤔' value={feedbackText} onChange={handleInputFeedbackText} />
        <input className='new-feedback-contact' placeholder='欢迎留下你的联系方式' value={feedbackContact} onChange={handleInputFeedbackContact} />
        <div className='new-btn'>
          <button className='talk-cancle' onClick={closeFeedback}>取消</button>
          <button className='talk-submit' onClick={handleSubmitFeedback} disabled={selectedOption}>确定</button>
        </div>
      </div>
      {
        showPhoneError && <div className="feedback-error">
          <i className="iconfont icon-error"></i>
          手机号格式错误！
        </div>
      }
    </Dialog>
  )
}

export default TalkNewProduct