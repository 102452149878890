/* eslint-disable */
import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import './Pagination.css'
import Box from "@mui/material/Box";

import baidu from './img/baidu.svg';
import google from './img/google.svg';
import copy from './img/copy.svg';
import like from './img/like.svg';
import mistake from './img/mistake.svg';
import collect from './img/collect.svg';
import baidu_ from './img/baidu-.svg';
import google_ from './img/google-.svg';
import copy_ from './img/copy-.svg';
import like_ from './img/like-.svg';
import mistake_ from './img/mistake-.svg';
import collect_ from './img/collect-.svg';
import related from './img/related.svg';
import def from './img/trans.svg'

import SendIcon from '@mui/icons-material/Send';
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {
    Checkbox,
    ClickAwayListener,
    createTheme, FormControlLabel,
    Grid, OutlinedInput,
    Paper,
    styled,
    ThemeProvider,
    Tooltip,
    tooltipClasses, Typography
} from "@mui/material";
import TriggersTooltips, {toast, domain, LightTooltip, isMobile} from "./Utils";
import axios from "axios";

const quotes_per_page = 20;

export function show_all_translation() {
    let rank = document.getElementsByClassName("rank")[0];
    let start_index = parseInt(rank.textContent.split('.')[0]) - 1;
    let all_showed = true;
    for (let i = start_index; i < start_index + quotes_per_page; i++) {
        let def = document.getElementsByClassName('def-display-' + i);
        if (def.length > 0 && def[0].style.display === "none") all_showed = false;
    }
    let has_def = false;
    for (let i = start_index; i < start_index + quotes_per_page; i++) {
        let def = document.getElementsByClassName('def-display-' + i);
        if (def.length > 0) {
            def[0].style.display = !all_showed ? "block" : "none";
            def[1].style.display = def[0].style.display;
            has_def = true
        }
    }
    if (!has_def) toast('本页内容暂无翻译')
}

export default function PaginationControlled(props) {
    /** mistake report dialog */
    const is_mobile= isMobile()
    const source_type = props.type;
    let splits = source_type.split('-')
    splits[0] = {'现': '现代文', '古': '古诗文', '谚': '谚语', '歇': '歇后语'}[splits[0]]
    const source_type_display = splits.join('-')
    const type = props.type[0];
    const query = props.query;
    const annotating = props.annotating;
    const search_func = props.search_func;
    let quotes = JSON.parse(props.quotes)
    for (let i = 0; i < quotes.length; i++) {
        quotes[i].author = quotes[i].author || '';
        quotes[i].work = quotes[i].work || '';
        quotes[i].definition = quotes[i].definition || '';
        if (type === '歇') quotes[i].quote = quotes[i].front + ' —— ' + quotes[i].back;
    }
    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });
    const [mistaken, setMistaken] = React.useState(false);
    const [currentQuoteIndex, setCurrentQuoteIndex] = React.useState(0);
    const handleSubmit = () => {
        let quote = quotes[currentQuoteIndex]
        let mistake_types = []
        if (document.getElementById('checkbox_mistake_quote').checked) mistake_types.push('quote');
        if (quote.author !== '' && document.getElementById('checkbox_mistake_author').checked) mistake_types.push('author');
        if (quote.work !== '' && document.getElementById('checkbox_mistake_work').checked) mistake_types.push('work');
        if (quote.definition !== '' && document.getElementById('checkbox_mistake_def').checked) mistake_types.push('def');
        if (document.getElementById('checkbox_mistake_type').checked) mistake_types.push('type');
        if ((source_type === '现-名言' || source_type === '古') && document.getElementById('checkbox_mistake_not').checked) mistake_types.push('not');
        if (document.getElementById('checkbox_mistake_sensitive').checked) mistake_types.push('sensitive');
        if (document.getElementById('checkbox_mistake_others').checked) mistake_types.push('others');
        let mistake_desc = document.getElementById('mistake_desc').value;
        axios.get(domain + 'mistake/', {
            params: {
                'quote_id': quote.id,
                'type': source_type,
                'mistake_types': JSON.stringify(mistake_types),
                'mistake_desc': mistake_desc,
                'unionid': localStorage.getItem('unionid'),
                'secret': localStorage.getItem('secret')
            }
        }).then(_ => {
            toast('提交成功，感谢您的反馈', 1000)
        })
        setMistaken(false);
    }
    const handleClose = () => {
        setMistaken(false);
    };

    function MistakeReportDialog() {
        let quote = quotes[currentQuoteIndex]
        return (
            // MUI: The `anchorEl` prop provided to the component is invalid.
            // The anchor element should be part of the document layout.
            // Make sure the element is present in the document or that it's not display none.
            <div>
                <Dialog
                    open={mistaken}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogContent className="mistake-dialog" sx={{pb: 0, pl: 2, pr: 2}}>
                        <div className="quote">
                            <p className="quote-text">{quote.quote}</p>
                            <button className="type-label" disabled>{source_type_display}</button>
                            {quote.definition !== '' &&
                                <p id={'def' + quote.index} className="def">{quote.definition}</p>
                            }
                            <span className="quote-author">
                                    {(quote.author !== '' || quote.work !== '') &&
                                        <hr className="dash"/>
                                    }
                                <span className="not-linkable">{quote.author}</span>
                                {quote.work !== '' &&
                                    <span className="not-linkable">{'《' + quote.work + '》'}</span>
                                }
                            </span>
                        </div>
                        <FormControlLabel className="quote" sx={{m: 0, p: 0}}
                                          control={<Checkbox sx={{m: 0, p: 0}} id="checkbox_mistake_quote"/>}
                                          label={<Grid className="kai-x">句子内容有误</Grid>}/>
                        {quote.author !== '' &&
                            <FormControlLabel className="quote" sx={{m: 0, p: 0}}
                                              control={<Checkbox sx={{m: 0, p: 0}}
                                                                 id="checkbox_mistake_author"/>}
                                              label={<Grid className="kai-x">作者有误</Grid>}/>
                        }
                        {quote.work !== '' &&
                            <FormControlLabel className="quote" sx={{m: 0, p: 0}}
                                              control={<Checkbox sx={{m: 0, p: 0}} id="checkbox_mistake_work"/>}
                                              label={<Grid className="kai-x">作品有误</Grid>}/>
                        }
                        {quote.definition !== '' &&
                            <FormControlLabel className="quote" sx={{m: 0, p: 0}}
                                              control={<Checkbox sx={{m: 0, p: 0}} id="checkbox_mistake_def"/>}
                                              label={<Grid className="kai-x">翻译有误</Grid>}/>
                        }
                        <FormControlLabel className="quote" sx={{m: 0, p: 0}}
                                          control={<Checkbox sx={{m: 0, p: 0}} id="checkbox_mistake_type"/>}
                                          label={<Grid className="kai-x">分类错误</Grid>}/>
                        {(source_type === '现-名言' || source_type === '古') &&
                            <FormControlLabel className="quote" sx={{m: 0, p: 0}}
                                              control={<Checkbox sx={{m: 0, p: 0}} id="checkbox_mistake_not"/>}
                                              label={<Grid className="kai-x">不是名言名句</Grid>}/>
                        }
                        <FormControlLabel className="quote" sx={{m: 0, p: 0}}
                                          control={<Checkbox sx={{m: 0, p: 0}} id="checkbox_mistake_sensitive"/>}
                                          label={<Grid className="kai-x">内容敏感</Grid>}/>
                        <FormControlLabel className="quote" sx={{m: 0, p: 0}}
                                          control={<Checkbox sx={{m: 0, p: 0}} id="checkbox_mistake_others"/>}
                                          label={<Grid className="kai-x">其他错误</Grid>}/>
                        <TextField
                            id="mistake_desc"
                            label={<span className="kai-x">填写错误描述</span>}
                            margin="dense" multiline rows={4}
                            defaultValue="" fullWidth variant="outlined"
                        />
                    </DialogContent>
                    <DialogActions sx={{pt: 0, mt: 0}}>
                        <Button onClick={handleSubmit} className="kai-x" sx={{p: 0, m: 0}}>提交</Button>
                        <Button onClick={handleClose} className="kai-x" sx={{p: 0, m: 0}}>取消</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    /** mistake report dialog end */

    function Quote(props) {
        const index = props.quote.index;
        const rank = index + 1;

        function click_like(e) {
            let like_input = document.getElementById('like-input' + index)
            let liked = like_input.value === 'liked'
            let remove = liked;

            like_input.value = liked ? '' : 'liked'
            liked = !liked;
            axios.get(domain + 'like/', {
                params: {
                    'query': query,
                    'type': source_type,
                    'good_quote_id': props.quote.id,
                    'op': remove ? 'remove' : 'add',
                    'unionid': localStorage.getItem('unionid'),
                    'secret': localStorage.getItem('secret')
                }
            }).then(_ => {
                //if (remove) toast('取消点赞成功');
                //else toast('点赞成功');
            })
            //FIXME: Bug的原因找到了 系统里有两份id一个 leftList+rightList 一个fullList，fullList的style没有被改变, 手机上使用的是fullList
            //let like_image = document.getElementById("like" + index)
            //like_image.setAttribute('src', (liked ? like_ : like))
            e.target.src = (liked ? like_ : like)
            e.target.animate([
                // keyframes
                {transform: 'scale(1.25)'},
                {transform: 'scale(1)'}
            ], {
                // timing options
                duration: 200,
                iterations: 1
            });
        }

        function click_copy(e) {
            axios.get(domain + 'user_action/', {
                params: {
                    'query': query,
                    'type': source_type,
                    'quote_id': props.quote.id,
                    'action': 'copy',
                    'unionid': localStorage.getItem('unionid'),
                    'secret': localStorage.getItem('secret')
                }
            }).then(_ => {
            })
            let author_string = (props.quote.author !== '') ? ' ' + props.quote.author : ''
            let work_string = (props.quote.work !== '') ? ' 《' + props.quote.work + '》' : ''
            navigator.clipboard.writeText(props.quote.quote + author_string + work_string).then(_ => {
            });//only https support
            /*
            let copy_buffer = document.getElementsByClassName('copy-buffer')[0];
            copy_buffer.value = props.quote.quote + author_string + work_string;  // 这里表示想要复制的内容
            copy_buffer.focus();
            copy_buffer.select();
            if (document.execCommand('copy')) {
                document.execCommand('copy');
            }
            copy_buffer.blur();*/
            toast('复制成功', 500);
            e.target.animate([
                // keyframes
                {transform: 'rotate(15deg)'},
                {transform: 'rotate(-30deg)'},
                {transform: 'rotate(5deg)'},
                {transform: 'rotate(0deg)'}
            ], {
                // timing options
                duration: 500,
                iterations: 1
            });
        }

        function click_relate() {
            axios.get(domain + 'user_action/', {
                params: {
                    'query': query,
                    'type': source_type,
                    'quote_id': props.quote.id,
                    'action': 'relate',
                    'unionid': localStorage.getItem('unionid'),
                    'secret': localStorage.getItem('secret')
                }
            }).then(_ => {
            })
            //TODO: 歇后语怎么考虑？
            document.getElementById('search-input').value = '->' + (props.quote.definition === '' ? props.quote.quote : props.quote.definition)
            search_func();
            document.getElementById('search-input').value = props.quote.quote
        }

        function click_mistake(quote_index) {
            setMistaken(true);
            setCurrentQuoteIndex(quote_index);
        }

        function click_engine() {
            axios.get(domain + 'user_action/', {
                params: {
                    'query': query,
                    'type': source_type,
                    'quote_id': props.quote.id,
                    'action': 'baidu',
                    'unionid': localStorage.getItem('unionid'),
                    'secret': localStorage.getItem('secret')
                }
            }).then(r => {
            })
            let author_string = (props.quote.author !== '') ? ' ' + props.quote.author : ''
            let work_string = (props.quote.work !== '') ? ' 《' + props.quote.work + '》' : ''
            let wd = props.quote.quote + author_string + work_string;
            if (type === '英') window.open("https://www.google.com/search?q=" + wd, "_blank");
            else window.open("https://www.baidu.com/s?ie=UTF-8&wd=" + wd, "_blank");
        }

        const [good, setGood] = React.useState(false);
        const [buttonStyle, setButtonStyle] = React.useState({display: 'none'});
        return (
            annotating ? (<div className="quote-wrapper-annotating" onClick={() => setGood(!good)}>
                        {/* TODO: 每条名句一个模态框导致变卡！！！ */}
                        <button className="rank">{rank}.</button>
                        <div className="quote">
                            <div className="quote-text not-linkable">
                                <div className="checkbox">
                                    <Stack>
                                        <Checkbox checked={good} sx={{p: 0, m: 0.2}} id={"checkbox" + index}/>
                                    </Stack>
                                </div>
                                {props.quote.quote}
                            </div>
                            <span className="quote-author">
                                {(props.quote.author !== '' || props.quote.work !== '') &&
                                    <hr className="dash"/>
                                }
                                <span className="not-linkable">{props.quote.author}</span>
                                {props.quote.work !== '' &&
                                    <span className="not-linkable">{'《' + props.quote.work + '》'}</span>
                                }
                            </span>
                        </div>
                    </div>
                ) :
                (
                    <div className="quote-wrapper"
                         onMouseEnter={e => {
                             setButtonStyle({display: 'inline-block'});
                         }}
                         onMouseLeave={e => {
                             setButtonStyle({display: 'none'})
                         }}
                    >
                        {/* TODO: 每条名句一个模态框导致变卡！！！ */}
                        <button className="rank">{rank}.</button>
                        <div className="quote">
                            <p className="quote-text">{props.quote.quote}</p>
                            {props.quote.definition !== '' &&
                                <Box>
                                    <p className={"def def-display-" + index} style={{display: 'none'}}>{props.quote.definition}</p>
                                </Box>
                            }
                            <div className={is_mobile?"logo-box-mobile": "logo-box"} style={buttonStyle}>
                                <LightTooltip title="此句正合我意">
                                    <img id={"like" + index} src={like} className={is_mobile?"logo-mobile": "logo"} alt="like" onClick={click_like}/>
                                </LightTooltip>
                            </div>

                            <div className={is_mobile?"logo-box-mobile": "logo-box"} style={buttonStyle}>
                                <LightTooltip title="复制此句">
                                    <img src={copy} className={is_mobile?"logo-mobile": "logo"} alt="copy" onClick={(e) => click_copy(e)}/>
                                </LightTooltip>
                            </div>

                            <div className={is_mobile?"logo-box-mobile": "logo-box"} style={buttonStyle}>
                                <LightTooltip title="在百度搜索此句">
                                    <img src={type === '英' ? google : baidu} className={is_mobile?"logo-mobile": "logo"} alt="engine" onClick={click_engine}/>
                                </LightTooltip>
                            </div>

                            <div className={is_mobile?"logo-box-mobile": "logo-box"} style={buttonStyle}>
                                <LightTooltip title="查找此句的近义句">
                                    <img src={related} className={is_mobile?"logo-mobile": "logo"} alt="related" onClick={() => click_relate()}/>
                                </LightTooltip>
                            </div>
                            {props.quote.definition !== '' &&
                                <div className={is_mobile?"logo-box-mobile": "logo-box"} style={buttonStyle}>
                                    <LightTooltip title="查看此句的翻译或解释">
                                        <img src={def} className={is_mobile?"logo-mobile": "logo"} alt="def"
                                             onClick={() => {
                                                 //FIXME: Bug的原因找到了 系统里有两份id一个 leftList+rightList 一个fullList，fullList的style没有被改变
                                                 let def = document.getElementsByClassName('def-display-' + index);
                                                 def[0].style.display = def[0].style.display === "none" ? "block" : "none";
                                                 def[1].style.display = def[0].style.display
                                             }}/>
                                    </LightTooltip>
                                </div>
                            }

                            <div className={is_mobile?"logo-box-mobile": "logo-box"} style={buttonStyle}>
                                <LightTooltip title="反馈此句的错误">
                                    <img src={mistake} className={is_mobile?"logo-mobile": "logo"} alt="mistake"
                                         onClick={() => click_mistake(index)}/>
                                </LightTooltip>
                            </div>
                            {/*<TriggersTooltips
                                placement="top-end"
                                trigger={
                                    <p className="quote-text">
                                        {type === 'twopart' ? props.quote.quote_front + ' —— ' + props.quote.quote_back : props.quote.quote}
                                    </p>
                                }
                                tip={
                                    <div className="quote-tips-wrapper">
                                        <div className="logo-box">
                                            {copy_img}
                                        </div>

                                        <div className="logo-box">
                                            <LikeLogo quote={props.quote} index={index}/>
                                        </div>

                                        <div className="logo-box">
                                            {engine_img}
                                        </div>

                                        <div className="logo-box">
                                            <img src={related} className={"logo"} alt="related"
                                                 onClick={() => {
                                                     document.getElementById('search-input').value = props.quote.quote
                                                     search_func();
                                                 }
                                                 }/>
                                        </div>

                                        <div className="logo-box">
                                            <img src={mistake} className="logo" alt="mistake"
                                                 onClick={() => click_mistake(props.quote.quote)}/>
                                        </div>
                                    </div>
                                }
                            />*/}
                            <span className="quote-author">
                                    {((props.quote.author !== '') || props.quote.work !== '') &&
                                        <hr className="dash"/>
                                    }
                                <span className="not-linkable">{props.quote.author}</span>
                                {props.quote.work !== '' &&
                                    <span className="not-linkable">{'《' + props.quote.work + '》'}</span>
                                }
                            </span>


                        </div>
                    </div>)
        )
    }


    let rendered = function (page_id, part) {
        for (let i = 0; i < quotes.length; i++) {
            quotes[i]['index'] = i;
        }
        let page_quotes;
        const start = (page_id - 1) * quotes_per_page;
        const end = page_id * quotes_per_page;
        const half_num = Math.floor(quotes_per_page / 2)
        if (part === 'left') page_quotes = quotes.slice(start, end - half_num)
        else if (part === 'right') page_quotes = quotes.slice(start + half_num, end)
        else page_quotes = quotes.slice(start, end) //'full'

        return page_quotes.map((quote, i) =>
            <span key={i}>
                <input id={"like-input" + quote.index} type="hidden" hidden="hidden" value={''}/>
                <Quote quote={quote}/>
            </span>
        );
    }

    const [page, setPage] = React.useState(1);
    const [list, setList] = React.useState(rendered(1, 'full'));
    const [leftList, setLeftList] = React.useState(rendered(1, 'left'));
    const [rightList, setRightList] = React.useState(rendered(1, 'right'));
    const handleChange = (event, value) => {
        setPage(value);
        setList(rendered(value, 'full'))
        setLeftList(rendered(value, 'left'))
        setRightList(rendered(value, 'right'))
    };


    return (
        annotating ?
            (<>
                    <Paper sx={{pt: 2, pr: 2, mt: 2}}>
                        {
                            quotes.slice(0, 10).map((quote, i) =>
                                <Quote quote={quote} key={i} index={i}/>
                            )
                        }
                    </Paper>
                </>
            ) :
            (<Stack spacing={2} alignItems="center" style={{"width":"100%"}} sx={{pt: 0}}>
                <MistakeReportDialog/>
                <input className="copy-buffer"/>
                {/* xl lg md sm: small xs:small */}
                <Stack direction="row" spacing={0}
                       sx={{width: 1, display: {md: 'block', sm: 'none', xs: 'none'}, pt: 0}}>
                    <Box className="half-page-left">{leftList}</Box>
                    <Box className="half-page-right">{rightList}</Box>
                </Stack>
                <Box alignItems="left"
                     sx={{width: 1, display: {xl: 'none', lg: 'none', md: 'none', sm: 'block', xs: 'block'}}}>
                    <Box>{list}</Box>
                </Box>
                <Pagination boundaryCount={0} count={Math.ceil(quotes.length / 20)} color="primary" page={page}
                            onChange={handleChange}
                            sx={{margin: 'auto'}}/>
            </Stack>)
    );
}