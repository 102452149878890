import React from 'react';
import {
  Button,
  Dialog
} from "@mui/material";
import new_image from '../../main/img/dialog_img.png';

function NewDialog(props) {
  const { showWarning, closeDialogForever, handleJumpshenyandayi } = props;

  return (
    <Dialog
      open={showWarning}
      maxWidth='620px'
      className='pc-mobile'
    >
      {/* <DialogActions
            style={{ paddingTop: 12, paddingRight: 16, zIndex: 10 }}
          >
            <CloseIcon
              onClick={closeDialog}
              style={{ width: 18, height: 18, color: '#7A7B7B', cursor: "pointer" }}
            />
          </DialogActions> */}
      <div className='dialog-container'>
        <img src={new_image} className='dialog-image' alt='longowhale' />
        <div className='dialog-box'>
          <h2 className='dialog-title'>焕然一新！新产品正式发布&nbsp;🎉</h2>
          <div className='dialog-content'>
            <p>亲爱的用户，为了带给大家更好的使用体验，WantQuotes 已经全面升级为<span className='lingo-whale'>「深言达意」</span>啦&nbsp;👏</p>
            <p>新产品包含 WantQuotes（据意查句）及 WantWords（反向词典）的全部功能，同时增加了收藏、联想词、历史记录等更多好用的新功能，希望你玩得开心，更希望大家能一如既往的支持我们&nbsp;🤩</p>
            <p> WantWords 及 WantQuotes <span style={{ color: '#FF4D4F' }}>即日起会停止更新，但仍可正常访问，</span>也可以通过深言达意随时切回这里。如果有任何问题，欢迎随时和我们反馈交流&nbsp;📝</p>
            <p>🐳&nbsp;我们深言达意见！</p>
          </div>
        </div>
      </div>
      <Button className='dialog-btn' style={{ backgroundColor: '#2D2D2D', margin: '0 auto' }} onClick={handleJumpshenyandayi}>
        去探索
      </Button>
      <Button className='close-btn' style={{ margin: '0 auto' }} onClick={closeDialogForever}>不再提示</Button>

    </Dialog>
  )
}

export default NewDialog