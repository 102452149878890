import * as React from "react";
import {Accordion, AccordionDetails, AccordionSummary, DialogContent, Divider, Link, Stack} from "@mui/material";
import wechat_group from "./img/wechat_group.jpeg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import './Notification.css';

export function Notification1(props) {
    return (
        <DialogContent sx={{pb: 0}}>
            <div className="notification-title">来自研发团队的一封信 | 有大事宣布！</div>
            <br/>
            <div className="notification-content">
                亲爱的WantQuotes用户，<br/>
                <div className='padding'></div>

                &emsp;&emsp;久违了！一眨眼夏天就过去了，今年北京的秋天似乎比以往来得更早一些。最近几个月我们研发团队经历了很多事情，也做了一些重要的决定，在此想分享给你。🙂 &nbsp;<br/>
                <div className='padding'></div>

                &emsp;&emsp;由于内容比较丰富，还请你点击<Link href="https://mp.weixin.qq.com/s/PcJtWrJBsaYAJXVkE0fTUg" target="_blank">这一链接</Link>打开新页面查看。<br/>
                <div className='padding'></div>
                <br/>

                &emsp;&emsp;如果你确实没有时间看完全文，也可以先看我们的太长不看版：<br/>
                <div className='padding'></div>

                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>} sx={{pt: 0, pb: 1}}>
                        &emsp;<Link href="#" underline="none">点此查看太长不看版</Link><br/>
                    </AccordionSummary>
                    <AccordionDetails sx={{m: 0, pt: 0}}>
                        <ul>
                            <li>在经过不太成功的志愿者开发模式的探索后，我们决定成立公司来对WantWords和WantQuotes进行维护和更新；</li>
                            <div className='padding'></div>
                            <li>不止于这两个产品，我们认为最近几年新一轮的人工智能技术变革将引领一场轰轰烈烈的文本处理智能化革命，而我们希望借此机会做一些真正有巨大价值的事情；🦾</li>
                            <div className='padding'></div>
                            <li>以我们所属的清华大学自然语言处理实验室为班底成立了一家科技创业公司——<strong>深言科技</strong>（DeepLang AI），有超一流的团队、背景、技术能力、发展前景；</li>
                            <div className='padding'></div>
                            <li>我们在<strong>招人</strong>！NLP算法、前后端开发、产品、设计都要！我们有完善的福利待遇、舒适的工作环境，执行10-7-5工作制，希望高效工作，快乐生活！（招聘相关的具体信息见<Link target="_blank" href="https://mp.weixin.qq.com/s?__biz=Mzg4ODc2NjQ4Ng==&mid=2247484089&idx=1&sn=3e3526473c96656c16856fa97b7336a7&scene=21#wechat_redirect">这篇文章</Link>）🙌</li>
                            <div className='padding'></div>
                            <li>后续我们将加快已有产品迭代和新产品开发的速度，现有产品功能将<strong>持续免费开放</strong>并会大幅提升使用体验。</li>
                        </ul>
                    </AccordionDetails>
                </Accordion>

                <div className='padding'></div>

                &emsp;&emsp;我们仍然希望你在使用时<strong>多给反馈</strong>，包括给语义相关的名句点赞👍 &nbsp;、反馈名句的错误，如果对WantQuotes有任何意见、建议、需求，也可以在网站右上角点击“反馈”提出，<strong>我们每一条反馈都会看</strong>（尽管无法一一回复或立刻解决）。有了你的反馈，WantQuotes会变得更好用。<br/>
                <div className='padding'></div>

                &emsp;&emsp;也欢迎更多人以更灵活的方式一同改进WantQuotes，无论是参与设计&开发，还是提出需求，反馈问题、意见或建议。欢迎你加入<strong>QQ群</strong>（群号850950531）或<strong>微信群</strong>（请扫最下方二维码或搜索微信号 DeepLang_FL，添加小助手微信，并备注“WQ用户”后等待拉你进群）。除了围绕WantQuotes进行交流之外，我们也有若干正在实验室内部开发的实用工具项目，会在群中邀请试用。👀 &nbsp;<br/>
                <div className='padding'></div>

                &emsp;&emsp;最后，感谢你的耐心阅读！希望WantQuotes帮到你。<br/><br/>
                <div className="letter-sign">WantQuotes研发团队</div>
                <div className="letter-sign">敬上</div>
                <div className="letter-sign">2022年9月14日</div>
                <br/>
                <Divider/>
                <br/>
                <strong><h4 className="faq">FAQ</h4></strong><br/>
                <strong>Q1：XX结果不对，和输入的描述没关系/意思相反</strong> <br/>
                A1：因为据意查句模型训练数据以及性能所限，很难避免查出不太相关甚至特别离谱的句子。一方面需要大家多多对查句结果点赞来帮助我们更好地训练模型，另一方面我们也会不断优化模型和数据库，提高查询精确度。<br/>
                <br/>
                <strong>Q2：XX句子的内容/作者/作品等有错误</strong> <br/>
                A2：现有的句子信息来自于很多来源，如果你发现某个句子的基本信息存在错误，请点击“反馈错误”，我们会收集一波反馈后集中修正。<br/>
                <br/>
                <strong>Q3：是否会出一个APP/小程序？</strong> <br/>
                A3：打磨好Web端之后会出的。<br/>
                <br/>
                <strong>Q4：目前的项目更新进展如何？</strong> <br/>
                A4：请访问<Link href='https://hieh97blhp.feishu.cn/docs/doccnoH9ncCZspo2Ubx79bpZ0Lh' target="_blank">此文档</Link>查看我们的更新历史、迭代进展和规划（包括WantQuotes以及其他项目）。<br/>
                <br/>
                <strong>Q5：想为此项目做贡献？</strong> <br/>
                A5：除了多反馈之外，你还可以将WantQuotes介绍给其他人，另外如果你有相对丰富的设计&开发经验，欢迎提供一些专业的建议，请联系岂凡超（微信号：fanchao_qi）。<br/>
                <br/>
                <strong>Q6：此前的致用户信在哪？</strong> <br/>
                A6：点
                <Link href='#' onClick={() => {
                    props.setVersion(0)
                }}>此</Link>
                查看上一封致用户信。<br/>
                <Stack alignItems={'center'} spacing={1} sx={{mt: 2}}>
                    <img alt='' src={wechat_group} className='wechat-group'/>
                    <p className='kai-x'>扫码进交流群了解更多</p>
                    <br/>
                </Stack>
            </div>
        </DialogContent>
    );
}

export function Notification0(props) {
    return (
        <DialogContent sx={{pb: 0}}>
            <div className="notification-title">来自研发团队的一封信</div>
            <br/>
            <div className="notification-content">
                致打开WantQuotes的你：<br/>
                <div className='padding'></div>

                &emsp;&emsp;你好呀，我们是WantQuotes研发团队。😆 &nbsp;<br/>
                <div className='padding'></div>

                &emsp;&emsp;与<Link href="https://wantwords.net/" underline="hover">WantWords反向词典</Link>一样，我们来自清华大学计算机系自然语言处理实验室（THUNLP）。我们此前通过WantWords帮助很多人更快地找到所需的词语，而WantQuotes则主要解决找句子的问题。<br/>
                <div className='padding'></div>

                &emsp;&emsp;其实我们早在一年半之前就启动了WantQuotes的研发，我们花费了大量时间收集、清洗数据，同时围绕名句的上下文推荐和语义理解及匹配开展了深入研究，分别提出了新的算法，在评测实验中获得了比现有其他方法更好的效果，并且已有<Link href="https://aclanthology.org/2022.acl-long.27.pdf" underline='hover'>一篇论文</Link>发表在本领域顶级会议。🏅 &nbsp;<br/>
                <div className='padding'></div>

                &emsp;&emsp;然而，当我们试用我们搭建的演示系统时，却发现使用体验并不让人满意。😔 &nbsp;<br/>
                <div className='padding'></div>

                &emsp;&emsp;由于我们想较为彻底地解决找句子的问题，所以要覆盖尽可能丰富的句子类型——事实上目前我们梳理出的4大类13小类已<strong>几乎覆盖了所有的名句类型</strong>——以及尽可能多的句子。为此，我们尝试从近百个来源收集、挖掘名句，工作量大倒不是问题，问题出在句子来源繁杂、质量参差不齐。我们想尽各种办法对数据进行过滤、清洗、去重等处理，但查到的名句中仍有不少存在种种错误。<br/>
                <div className='padding'></div>

                &emsp;&emsp;另外，尽管我们采用的语义匹配算法已实现当前最佳性能，但距理想状态仍有不小的差距。很多名句言简意赅，且含义丰富，人经常都难以理解其弦外之音，遑论机器。🤖 &nbsp;<br/>
                <div className='padding'></div>

                &emsp;&emsp;于是我们迟迟没有正式发布WantQuotes，而是不断地对其进行打磨和完善。直到现在，它总算达到堪堪拿得出手的状态。但我们深知目前WantQuotes的准确性和使用体验还有很大提升空间，之后也会持续进行优化。<br/>
                <div className='padding'></div>

                &emsp;&emsp;同时，我们也真诚希望你能在使用时<strong>多给反馈</strong>，包括给语义相关的名句点赞👍 &nbsp;、反馈名句的错误，如果对WantQuotes有任何意见、建议、需求，也可以在网站右上角点击“反馈”提出，<strong>我们每一条反馈都会看</strong>（尽管无法一一回复或立刻解决）。有了你的反馈，上述问题一定能更快地解决，WantQuotes会变得更好用。<br/>
                <div className='padding'></div>

                &emsp;&emsp;另外也欢迎你加入<strong>QQ群</strong>（群号850950531）或<strong>微信群</strong>（请扫最下方二维码或搜索微信号 DeepLang_FL，添加小助手微信，并备注“WQ用户”），除了围绕WantQuotes进行交流之外，我们也有若干正在实验室内部开发的实用工具项目，会在群中邀请试用。👀 &nbsp;<br/>
                <div className='padding'></div>

                &emsp;&emsp;最后，感谢你的耐心阅读！希望WantQuotes帮到你。<br/><br/>
                <div className="letter-sign">WantQuotes研发团队</div>
                <div className="letter-sign">敬上</div>
                <div className="letter-sign">2022年5月19日于清华大学</div>
                <br/>
                <Divider/>
                <br/>
                <strong><h4 className="faq">FAQ</h4></strong><br/>
                <strong>Q1：为什么需要登录？</strong> <br/>
                A1：一方面方便后续开发收藏、发布等功能，另一方面也可以提高网站安全性。<br/>
                <br/>
                <strong>Q2：会出小程序/APP吗？</strong> <br/>
                A2：在打磨好web端之后，如果大家有此需求的话会开发的。<br/>
                <br/>
                <strong>Q3：目前的项目进展如何？</strong> <br/>
                A3：请访问<Link href='https://hieh97blhp.feishu.cn/docs/doccnoH9ncCZspo2Ubx79bpZ0Lh' underline="hover">此文档</Link>查看我们的更新历史、迭代进展和规划（包括WantQuotes以及其他项目）。<br/>
                <br/>
                <strong>Q4：想为此项目做贡献？</strong> <br/>
                A4：除了多反馈之外，你还可以将WantQuotes介绍给其他人，另外如果你有相对充裕的时间和相对丰富的设计（平面/UI/UX）或开发（前后端/APP）经验，并且愿意参与设计或开发，请联系岂凡超（微信号：fanchao_qi）。<br/>
                <Stack alignItems={'center'} spacing={1} sx={{mt: 2}}>
                    <img alt='' src={wechat_group} className='wechat-group'/>
                    <p className='kai-x'>扫码进交流群了解更多</p>
                    <br/>
                </Stack>
            </div>
        </DialogContent>
    );
}