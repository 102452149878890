import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CampaignIcon from '@mui/icons-material/Campaign';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FaceIcon from '@mui/icons-material/Face';
import LogoutIcon from '@mui/icons-material/Logout';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Badge from '@mui/material/Badge';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import './Main.css';

/*import * as React from 'react';*/
import NearMeIcon from '@mui/icons-material/NearMe';
import SearchIcon from '@mui/icons-material/Search';
import SendIcon from "@mui/icons-material/Send";
import {
    Accordion, AccordionDetails,
    AccordionSummary, Alert,
    AppBar,
    Box,
    Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle,
    FormControlLabel,
    Grid, InputAdornment,
    LinearProgress,
    Link, MenuItem, Select,
    Stack, styled, Toolbar,
    Typography
} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import axios from "axios";
import PaginationControlled, { show_all_translation } from "./Pagination";
import TriggersTooltips, { DefIcon, domain, isMobile, LightTooltip, toast } from "./Utils";

import TextField from "@mui/material/TextField";
import baidu from './img/baidu.svg';
import copy from './img/copy.svg';
import like_ from './img/like-.svg';
import like from './img/like.svg';
import site_logo from './img/logo.svg';
import mistake from './img/mistake.svg';
import related from './img/related.svg';
import def from './img/trans.svg';
import wechat_group from './img/wechat_group.jpeg';
import { Notification0, Notification1 } from "./Notifications";

let timer = null; //登录轮询计时器

export default function Main(props) {
    const TEST = props.test;
    // 图片预加载
    const images = [
        baidu,
        copy,
        like,
        mistake,
        like_,
        related,
        def,
        site_logo,
        wechat_group
    ];
    for (let i = 0; i < images.length; i++) new Image().src = images[i];
    const is_mobile = isMobile();
    const [loginState, setLoginState] = React.useState(localStorage.getItem('unionid') !== null ? 2 : 0); //0未登录，1正在登录，2已登录

    function AnnotationDemo() {
        const [demoOpen, setDemoOpen] = React.useState(true);
        return (
            <Dialog disableEscapeKeyDown open={demoOpen} scroll="body" sx={{m: 0, p: 0, 'z-index': 1600}}>
                <DialogTitle id="scroll-dialog-title" className="kai-xx">标注说明</DialogTitle>
                <DialogContent className="kai-x">
                    <p>你的标注将会用于定量评价系统的能力，改善推荐的效果。</p>
                    <p>标注时，对于你的一次查询，你只需要将前10个结果中与你要查的意思相契合的结果前打勾。</p>
                    <span>例如，对"<strong>烦恼的意义</strong>"进行标注：</span>
                    <Stack>
                        {
                            [
                                {'quote': '烦恼和痛苦是人生的导师。', 'checked': true},
                                {'quote': '烦恼是心智的沉溺。', 'checked': false},
                                {'quote': '一切生命的本质就是苦恼。', 'checked': true},
                                {'quote': '爱情是充满烦恼的喜悦。', 'checked': false},
                                {'quote': '没有一回的快乐是无烦扰的。', 'checked': false},

                                {'quote': '要言不烦也。', 'checked': false},
                                {'quote': '用人就是用苦恼。', 'checked': false},
                                {'quote': '一定量的厌烦也许是生命中必需的组成部分。', 'checked': true},
                                {'quote': '说不定我们烦恼的事情实际上微不足道。', 'checked': false},
                                {'quote': '没有一个人能真正了解另一个人的烦恼或喜悦。', 'checked': false},
                            ].map((quote, i) =>
                                <div key={i}>
                                    <span className="rank">{i + 1}.</span>
                                    <FormControlLabel className="quote" sx={{m: 0, p: 0}}
                                                      control={<Checkbox checked={quote['checked']}
                                                                         sx={{mt: '0.2em', p: 0}}
                                                                         onChange={() => {
                                                                         }}/>}
                                                      label={<span className="kai-x">{quote['quote']}</span>}/>
                                </div>
                            )
                        }

                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDemoOpen(false)} className='kai-x' sx={{m: 0, p: 0}}>确定</Button>
                </DialogActions>
            </Dialog>
        )
    }

    function search_func() {
        document.getElementById('use-guide-grid').style.display = 'none'
        let query_input = document.getElementById('search-input')

        let query = query_input.value;

        let relate_id = ''
        if (query.slice(0, 2) === '->') {
            relate_id = 'r';
            query = query.slice(2);
        }
        let type = document.getElementById('type-input').value
        if (query === '') {
            relate_id = 'e';
            let examples = {
                '现': [''],
                '现-名言': ['树立远大志向', '勇敢面对困难', '创新', '为人民服务'],
                '现-佳句': ['自信', '月色很美'],
                '现-佳句-文学': ['自信', '月色很美'],
                '现-佳句-诗歌': ['爱情'],
                '现-佳句-其他': ['自信', '月色很美'],
                '现-网络': ['喜欢你'],
                '现-台词': ['遇见你很幸运', '家庭很重要'],
                '现-台词-影视剧': ['遇见你很幸运', '家庭很重要', '做事要务实'],
                '现-台词-动漫': ['遇见你很幸运', '家庭很重要', '别太依赖别人'],
                '现-台词-综艺': ['面对困难'],
                '古': ['朋友要互相帮助', '要提前做好准备', '长命百岁'],
                '谚': ['珍惜时间'],
                '谚-谚语': ['珍惜时间'],
                '谚-俗语': ['尽力而为'],
                '谚-惯用语': ['说话算话'],
                '歇': ['开心'],
            }[type]
            query = examples[Math.floor(Math.random() * examples.length)]
            document.getElementById('search-input').value = query;
        }
        if (query_input.disabled) return;
        query_input.disabled = true;
        ReactDOM.render(
            <LinearProgress/>,
            document.getElementById('quotes-view')
        );
        /* restore original query */
        axios.get(domain + (TEST ? 'filtered_semantic/' : 'semantic/'), {
            params: {
                'query': query,
                'type': type,
                'unionid': localStorage.getItem('unionid'),
                'secret': localStorage.getItem('secret'),
                'relate_id': relate_id
            }
        }).then(response => {
            if (TEST && response.data.quotes[0]['quote'] === 'forbidden') {
                query_input.disabled = false;
                ReactDOM.render(
                    <>
                        <Alert severity="error">
                            <span className="kai-xmx">抱歉，系统暂不支持该查询，请尝试其他输入。如果有误，请点击
                            <Link
                                className="sensitive-alert-report"
                                onClick={() => {
                                    axios.get(domain + 'sensitive_report/', {
                                        params: {
                                            'suggestion': query,
                                            'contact': '<SENSITIVE_REPORT>',
                                            'unionid': localStorage.getItem('unionid') || '',
                                            'secret': localStorage.getItem('secret') || ''
                                        }
                                    }).then(response => {
                                        toast('感谢您的反馈')
                                    })
                                }}
                            >
                                上报
                            </Link>。
                            </span>
                        </Alert>
                    </>,
                    document.getElementById('quotes-view')
                );
                return;
            }
            let quotes = response.data.quotes
            let top_sim = response.data.top_sim
            let quotes_string = JSON.stringify(quotes)
            //let quotes_string = JSON.stringify(quotes).replaceAll(type, 'quote')//TODO: 统一quote表示，不要换字符串
            ReactDOM.render(
                <>
                    <Stack alignItems="center" direction='column' sx={{pt: 0}}>
                        {top_sim < 0.65 &&
                            <Stack direction="row" alignItems="center" spacing={1}>

                                <ErrorOutlineIcon color="warning" fontSize="small"/>
                                <span className="no-sim-warning">
                                    可能没有符合输入描述意思的名句
                                </span>
                            </Stack>
                        }
                        <Stack direction="row" spacing={1} sx={{pt: 0}}>
                            {(type[0] === '古' || type[0] === '谚' || type[0] === '歇') &&
                                <LightTooltip title="显示此页所有翻译">
                                    <div>
                                        <DefIcon className="translate-all-button" fontSize="small"
                                                 onClick={show_all_translation}/>
                                    </div>
                                </LightTooltip>
                            }
                            <TriggersTooltips
                                placement="right"
                                trigger={
                                    <div>
                                        <EditIcon className="annotation-button" fontSize="small"/>
                                    </div>
                                }
                                tip={
                                    <Stack spacing={'3px'}>
                                        <Typography className="annotation-tip">我要标注</Typography>
                                        <Button variant="outlined" endIcon={<QuestionMarkIcon/>} className="kai-xx"
                                                onClick={() => {
                                                    ReactDOM.render(<AnnotationDemo/>,
                                                        document.getElementById("annotation-demo"))
                                                }}>
                                            如何标注
                                        </Button>
                                        <span id="annotation-demo"/>
                                        <Button variant="outlined" endIcon={<EditIcon/>} className="kai-xx"
                                                color="success"
                                                onClick={() => {
                                                    ReactDOM.render(
                                                        <>
                                                            <div className="annotation-desc kai-x">
                                                                请对于"{query}"勾上推荐合适的结果
                                                            </div>
                                                            <PaginationControlled quotes={quotes_string}
                                                                                  query={query}
                                                                                  lang={'chinese'}
                                                                                  type={type} annotating={true}/>
                                                            <Grid container justifyContent="center" sx={{pt: 2}}>
                                                                <Button variant="contained" endIcon={<SendIcon/>}
                                                                        className="kai-xx"
                                                                        onClick={() => {
                                                                            let good_quote_ids = [];
                                                                            let bad_quote_ids = [];
                                                                            for (let i = 0; i < 10; i++) {
                                                                                if (document.getElementById('checkbox' + i).checked) {
                                                                                    good_quote_ids.push(quotes[i]['id'])
                                                                                } else {
                                                                                    bad_quote_ids.push(quotes[i]['id'])
                                                                                }
                                                                            }
                                                                            axios.get(domain + 'annotate/', {
                                                                                params: {
                                                                                    'query': query,
                                                                                    'type': type,
                                                                                    'good_quote_ids': JSON.stringify(good_quote_ids),
                                                                                    'bad_quote_ids': JSON.stringify(bad_quote_ids),
                                                                                    'unionid': localStorage.getItem('unionid'),
                                                                                    'secret': localStorage.getItem('secret')
                                                                                }
                                                                            }).then(response => {
                                                                                toast('提交成功')
                                                                                ReactDOM.render(
                                                                                    <PaginationControlled
                                                                                        quotes={quotes_string}
                                                                                        query={query}
                                                                                        lang={'chinese'}
                                                                                        type={type}
                                                                                        annotating={false}
                                                                                        search_func={search_func}
                                                                                    />, document.getElementById('quotes-view')
                                                                                )
                                                                            })
                                                                        }}
                                                                >
                                                                    提交
                                                                </Button>
                                                            </Grid>
                                                        </>
                                                        , document.getElementById('quotes-view'))

                                                }}>
                                            开始标注
                                        </Button>
                                    </Stack>
                                }
                            />
                        </Stack>
                        <PaginationControlled quotes={quotes_string} query={query} lang={'chinese'}
                                              type={type} annotating={false}
                                              search_func={search_func}/>
                    </Stack>
                </>,
                document.getElementById('quotes-view')
            );
            document.getElementById('search-grid').scrollIntoView(true);
            query_input.disabled = false;
        })
    }

    function SearchInput() {
        const [filter, setFilter] = React.useState('现-名言');

        function TypeSelect() {
            const BootstrapInput = styled(InputBase)(({theme}) => ({
                'label + &': {
                    marginTop: theme.spacing(3),
                },
                '& .MuiInputBase-input': {
                    borderRadius: 4,
                    position: 'relative',
                    backgroundColor: '#eaeff1', //theme.palette.background.paper,
                    border: '0px solid #ced4da',
                    fontSize: 'large',
                    padding: '10px 26px 10px 12px',
                    transition: theme.transitions.create(['border-color', 'box-shadow']),
                    // Use the system font instead of the default Roboto font.
                    fontFamily: [
                        '楷体',
                        'STKaiti',
                        '-apple-system',
                        'BlinkMacSystemFont',
                        '"Segoe UI"',
                        'Roboto',
                        '"Helvetica Neue"',
                        'Arial',
                        'sans-serif',
                        '"Apple Color Emoji"',
                        '"Segoe UI Emoji"',
                        '"Segoe UI Symbol"',
                    ].join(','),
                    '&:focus': {
                        borderRadius: 4,
                        borderColor: '#80bdff',
                        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
                    },
                },
            }));
            return (
                <Box component="form" sx={{display: 'flex', flexWrap: 'wrap'}}>
                    <input id="type-input" type="hidden" hidden="hidden" value={filter}/>
                    <Select value={filter[0]}
                            onChange={(event) => {
                                let value = event.target.value;
                                setFilter(value === '现' ? '现-名言' : value);
                            }}
                            input={<BootstrapInput startAdornment={<InputAdornment sx={{mr: 1, p: 0}} position="start"><span className="type-select-label">大类</span></InputAdornment>}/>}
                            IconComponent={ArrowDropDownIcon}
                            sx={{m: 1, mr: {xl: 5, lg: 5, md: 5, sm: 0, xs: 0}, width: 150}}
                    >
                        <MenuItem className="option-title-menu" value={"现"}><span className="option-title">现代</span></MenuItem>
                        <MenuItem className="option-title-menu" value={"古"}><span className="option-title">古诗文</span></MenuItem>
                        <MenuItem className="option-title-menu" value={"谚"}><span className="option-title">熟语</span></MenuItem>
                        <MenuItem className="option-title-menu" value={"歇"}><span className="option-title">歇后语</span></MenuItem>
                    </Select>
                    <Select id="source_type"
                            value={filter}
                            renderValue={(value) => {
                                let splits = value.split('-');
                                let type2 = splits[splits.length - 1];
                                if (type2.length === 1) type2 = "不限"
                                return <span className="option-title">{type2}</span>
                            }}
                            onChange={(event) => {
                                setFilter(event.target.value)
                            }}
                            MenuProps={{
                                MenuListProps: {
                                    disablePadding: true
                                }
                            }
                            }
                            style={{visibility: (filter[0] === '古' || filter[0] === '歇') ? 'hidden' : 'visible'}}
                            disabled={filter[0] === '古' || filter[0] === '歇'}
                            input={<BootstrapInput style={{visibility: (filter[0] === '古' || filter[0] === '歇') ? 'hidden' : 'visible'}}
                                                   startAdornment={<InputAdornment sx={{mr: 1, p: 0}} position="start"><span className="type-select-label">小类</span></InputAdornment>}/>}
                            IconComponent={ArrowDropDownIcon}
                            sx={{m: 1, width: 150}}
                    >
                        {filter[0] === "现" && <MenuItem className="option-title-menu" value={"现"}><span className="option-title">不限</span></MenuItem>}
                        {filter[0] === "现" && <MenuItem className="option-title-menu" value={"现-名言"}><span className="option-title">名言</span></MenuItem>}
                        {filter[0] === "现" && <MenuItem className="option-title-menu" value={"现-佳句"}><span className="option-title">佳句</span></MenuItem>}
                        {filter[0] === "现" && <MenuItem className="option-item-menu" value={"现-佳句-文学"}><span className="tree-mark">├</span><span className="option-item">文学</span></MenuItem>}
                        {filter[0] === "现" && <MenuItem className="option-item-menu" value={"现-佳句-诗歌"}><span className="tree-mark">├</span><span className="option-item">诗歌</span></MenuItem>}
                        {filter[0] === "现" && <MenuItem className="option-item-menu" value={"现-佳句-其他"}><span className="tree-mark">└</span><span className="option-item">其他</span></MenuItem>}
                        {filter[0] === "现" && <MenuItem className="option-title-menu" value={"现-网络"}><span className="option-title">网络</span></MenuItem>}
                        {filter[0] === "现" && <MenuItem className="option-title-menu" value={"现-台词"}><span className="option-title">台词</span></MenuItem>}
                        {filter[0] === "现" && <MenuItem className="option-item-menu" value={"现-台词-影视剧"}><span className="tree-mark">├</span><span className="option-item">影视剧</span></MenuItem>}
                        {filter[0] === "现" && <MenuItem className="option-item-menu" value={"现-台词-动漫"}><span className="tree-mark">├</span><span className="option-item">动漫</span></MenuItem>}
                        {filter[0] === "现" && <MenuItem className="option-item-menu" value={"现-台词-综艺"}><span className="tree-mark">└</span><span className="option-item">综艺</span></MenuItem>}

                        {filter[0] === "古" && <MenuItem className="option-title-menu" value={"古"}><span className="option-title">暂无</span></MenuItem>}

                        {filter[0] === "谚" && <MenuItem className="option-title-menu" value={"谚"}><span className="option-title">不限</span></MenuItem>}
                        {filter[0] === "谚" && <MenuItem className="option-title-menu" value={"谚-谚语"}><span className="option-title">谚语</span></MenuItem>}
                        {filter[0] === "谚" && <MenuItem className="option-title-menu" value={"谚-俗语"}><span className="option-title">俗语</span></MenuItem>}
                        {filter[0] === "谚" && <MenuItem className="option-title-menu" value={"谚-惯用语"}><span className="option-title">惯用语</span></MenuItem>}

                        {filter[0] === "歇" && <MenuItem className="option-title-menu" value={"歇"}><span className="option-title">暂无</span></MenuItem>}
                    </Select>
                </Box>
            )
        }

        return (
            <Stack direction="column" sx={{alignItems: 'center'}} style={{width: '100%'}}>
                <Paper sx={{alignItems: 'center', width: {xl: "80%", lg: "80%", md: "80%", sm: "100%", xs: "100%"}}}>
                    <Grid container alignItems="center" className="search-input" sx={{display: 'flex'}}>
                        {/*
                        <Grid item sx={{m: '2px'}}>
                            <DialogTypeSelect/>
                        </Grid>
                        */}
                        <Grid item xs>
                            <InputBase
                                fullWidth
                                sx={{p: '1px', pl: '15px'}}
                                placeholder={loginState === 2 ? "请输入描述，或直接搜索查看示例" : '请先通过微信登录'}
                                inputProps={{style: {fontSize: 21}}}
                                onKeyPress={(ev) => {
                                    if (ev.key === 'Enter') search_func()
                                }}
                                onClick={() => {
                                    if (loginState !== 2) {
                                        toast('请先登录')
                                    }
                                }}
                                id="search-input"
                                disabled={loginState !== 2}
                            />
                        </Grid>
                        <Grid item>
                            <IconButton sx={{p: '10px'}} color="primary" onClick={search_func} disabled={loginState !== 2}>
                                <SearchIcon/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </Paper>
                <TypeSelect/>
            </Stack>
        );
    }

    function UserInfoDialog() {
        return (
            <Box alignItems="center">
                {loginState !== 2 ?
                    <IconButton color="primary" onClick={() => setLoginState(1)}>
                        <AccountCircleIcon sx={{mr: 1}} fontSize="small"/>
                        <Typography variant="body2" color="text.secondary">
                            <span className="feedback">{'未登录'}</span>
                        </Typography>
                    </IconButton> :
                    <TriggersTooltips
                        placement="bottom-start"
                        trigger={
                            <IconButton color="primary">
                                <AccountCircleIcon sx={{mr: 1}} fontSize="small"/>
                                <Typography variant="body2" color="text.secondary">
                                    <span className="feedback">{'已登录'}</span>
                                </Typography>
                            </IconButton>
                        }
                        tip={
                            <>
                                <Stack direction="row" sx={{alignItems: 'center'}}>
                                    <FaceIcon color="primary" sx={{m: 1}} fontSize="small"/>
                                    <Typography variant="body2" color="text.secondary">
                                    <span className="feedback">
                                    wechat-{
                                        localStorage.getItem('unionid') === null ?
                                            '' : localStorage.getItem('unionid').slice(0, 10)
                                    }
                                    </span>
                                    </Typography>

                                </Stack>
                                <IconButton color="primary" onClick={() => {
                                    localStorage.removeItem('unionid')
                                    localStorage.removeItem('secret')
                                    setLoginState(0);
                                }}>
                                    <LogoutIcon sx={{mr: 1}} fontSize="small"/>
                                    <Typography variant="body2" color="text.secondary">
                                        <span className="feedback">退出登录</span>
                                    </Typography>
                                </IconButton>
                            </>
                        }/>

                }

            </Box>
        );
    }

    function FeedbackDialog() {
        const [open, setOpen] = React.useState(false);

        const handleClickOpen = () => {
            setOpen(true);
        };

        const handleClose = () => {
            setOpen(false);
        };

        const handleSubmit = () => {
            let suggestion = document.getElementById('suggest-input').value
            let contact = document.getElementById('contact-input').value
            if (suggestion === '') return;
            axios.get(domain + 'suggest/', {
                params: {
                    'suggestion': suggestion,
                    'contact': contact,
                    'unionid': localStorage.getItem('unionid') || '',
                    'secret': localStorage.getItem('secret') || ''
                }
            }).then(response => {
            });
            setOpen(false);
            toast('提交成功，感谢您的反馈')
        }

        return (
            <Box alignItems="center">
                <IconButton color="primary" onClick={handleClickOpen}>
                    <SendIcon sx={{mr: 1}} fontSize="small"/>
                    <Typography variant="body2" color="text.secondary">
                        <span className="feedback">反馈</span>
                    </Typography>
                </IconButton>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>您对网站有何意见或建议？</DialogTitle>
                    <DialogContent sx={{pb: 0}}>
                        <TextField
                            id="suggest-input"
                            label={<span className="kai-x">填写意见或建议</span>}
                            margin="dense" multiline rows={4}
                            defaultValue="" fullWidth variant="outlined"
                        />
                        <TextField
                            id="contact-input"
                            sx={{pl: 1, pr: 1}}
                            label={<Grid sx={{pl: 1}} className="kai-x">联系方式(选填)</Grid>}
                            margin="dense" fullWidth variant="standard"
                        />
                    </DialogContent>

                    <DialogActions sx={{pt: 0, mt: 0}}>
                        <Button onClick={handleSubmit} className="kai-x" sx={{p: 0, m: 0}}>提交</Button>
                        <Button onClick={handleClose} className="kai-x" sx={{p: 0, m: 0}}>取消</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        );
    }

    function NotificationDialog() {
        const current_notification = '2';
        const [open, setOpen] = React.useState(false);
        const [version, setVersion] = React.useState(1);
        return (
            <Box alignItems="center">
                <IconButton color="primary" onClick={() => {
                    setOpen(true);
                    setVersion(1)
                }}>
                    <Badge badgeContent={
                        (localStorage.getItem('viewed_notification') || '') === current_notification ? 0 : '新'
                    } color="error" overlap="circular" anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}>
                        <CampaignIcon sx={{mr: 1}} fontSize="medium"/>
                    </Badge>
                    <Typography variant="body2" color="text.secondary">
                        <span className="feedback">公告</span>
                    </Typography>
                </IconButton>
                <Dialog open={open} onClose={() => {
                    setOpen(false);
                }}>
                    {/*<DialogTitle>公告</DialogTitle>*/}

                    <DialogActions sx={{pt: 1, pb: 0}}>
                        <CloseIcon onClick={() => {
                            localStorage.setItem('viewed_notification', current_notification);
                            setOpen(false);
                        }} sx={{m: 0, p: 0}}/>
                    </DialogActions>
                    <>{
                        version === 1 ? <Notification1 setVersion={setVersion}/> : <Notification0/>
                    }
                    </>

                </Dialog>
            </Box>
        );
    }

    function Header() {
        return (
            <React.Fragment>
                <AppBar
                    component="div"
                    position="relative"
                    elevation={0}
                    sx={{bgcolor: '#eaeff1', pb: 0}}
                >
                    <Toolbar>
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item xs>
                                <Typography color="inherit" variant="h5" component="h1">
                                    {!is_mobile &&
                                        <img className="site-logo" src={site_logo} alt=""/>
                                    }
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Stack direction='row'>
                                    <NotificationDialog align="center"/>
                                    <FeedbackDialog align="center"/>
                                    <UserInfoDialog align="center"/>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </React.Fragment>
        );
    }

    function LoginDialog(props) {

        function HelpDialog(props) {
            const [open, setOpen] = React.useState(false);
            return (
                <Grid container justifyContent="center">
                    <IconButton color="primary" onClick={() => {
                        setOpen(true);
                    }}>
                        <NearMeIcon sx={{mr: 1}} fontSize="medium"/>
                        <Typography variant="body2" color="text.secondary" sx={{textAlign: 'center'}}>
                            <span className="mobile-guide">手机端登录指南</span>
                        </Typography>
                    </IconButton>
                    <Dialog open={open} onClose={() => {
                        setOpen(false);
                    }}>
                        <DialogTitle className="mobile-guide">手机端登录指南</DialogTitle>
                        <DialogContent sx={{pb: 0}}>
                            <span className="mobile-hint">
                                <div>
                                    <strong>方法1：</strong>使用微信<br/>

                                    <ul className='ul'>
                                        <li>

                                    微信任意聊天窗口中发送网址：wantquotes.net <ContentCopyIcon onClick={() => {
                                            navigator.clipboard.writeText('https://wantquotes.net').then(_ => {
                                            })
                                            toast('复制成功')
                                        }}/>，点击即可打开网站，长按二维码并关注公众号即可。<br/>
                                        </li>
                                    </ul>
                                    <br/>
                                    <strong>方法2：</strong>使用手机浏览器<br/>
                                    <ul className='ul'>
                                        <li>
                                            将二维码图片分享到微信或保存到手机相册（截屏或长按图片保存），打开微信识别二维码，关注公众号即可。<br/>
                                        </li>
                                    </ul>


                                </div>
                            </span>
                        </DialogContent>

                        <DialogActions sx={{pt: 0, mt: 0, justifyContent: 'center'}}>
                            <Button onClick={() => {
                                setOpen(false);
                            }} className="kai-x" sx={{p: 0, m: 0}}>我知道啦</Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            );
        }

        axios.get(domain + 'get_qrcode/').then(response => {
            let ticket = response.data.ticket;
            let scene_id = response.data.scene_id;
            ReactDOM.render(
                <img id='login-qrcode' className="qrcode" style={{'height': '250px', 'width': '250px'}}
                     src={'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=' + ticket}
                     alt=""/>,
                document.getElementById('login-qrcode')
            );
            /*const chatSocket = new WebSocket(ws_domain + scene_id);

            chatSocket.onmessage = function (e) {
                //存储用户登录凭证
                const data = JSON.parse(e.data);
                localStorage.removeItem('unionid')
                localStorage.removeItem('secret')
                localStorage.setItem('unionid', data.unionid);
                localStorage.setItem('secret', data.secret);
                //chatSocket.close() //关闭连接 FIXME: WHAT THE FUCK!!??
                setLoginState(2);
            };*/
            let count = 0;

            function login_check() {
                count++;

                axios.get(domain + 'login_check/', {
                    params: {
                        'scene_id': scene_id,
                    }
                }).then(status_res => {
                    if (timer !== null) {//只允许存在一个timer
                        clearTimeout(timer);//清理定时任务
                        timer = null;
                    }
                    let res = status_res.data;
                    if (!res.login) {//根据返回状态判断
                        if (count < 60) {// 3分钟
                            timer = setTimeout(() => {
                                login_check()
                            }, 3000)
                            console.log(timer)
                        }
                    } else {
                        clearTimeout(timer);//清理定时任务
                        timer = null;
                        console.log(res.unionid);
                        //存储用户登录凭证
                        localStorage.removeItem('unionid')
                        localStorage.removeItem('secret')
                        localStorage.setItem('unionid', res.unionid);
                        localStorage.setItem('secret', res.secret);
                        setLoginState(2);
                    }
                })
            }

            login_check();
        })

        return (
            <Dialog open={props.open}>
                <DialogActions sx={{pt: 1, pb: 0}}>
                    <CloseIcon onClick={() => setLoginState(0)} sx={{m: 0, p: 0}}/>
                </DialogActions>
                <DialogTitle sx={{p: 0, m: 0}}>
                    <Grid sx={{pt: 0, pb: 1}} container justifyContent="center" className="login-title"
                          color={"#104172"}>WantQuotes</Grid>
                    <Grid container justifyContent="center" className="kai">微信扫码登录</Grid>
                    <Grid container justifyContent="center" className="kai" sx={{fontSize: '0.85em !important'}}>首次登录需关注公众号</Grid>
                </DialogTitle>
                <DialogContent>
                    <Box id='login-qrcode' width='250px' height='250px'>
                        <br/>
                        <Grid container justifyContent="center" className="qrcode-warning">如果二维码没有出现，可能是由于访问人数过多，服务器压力过大，请稍等一会儿，请不要反复刷新</Grid>
                    </Box>
                    {is_mobile &&
                        <Grid container justifyContent="center">
                            <Stack>
                                <Grid className="mobile-hint">手机端推荐从微信中进入网站</Grid>
                                <HelpDialog/>
                            </Stack>
                        </Grid>}
                </DialogContent>
            </Dialog>)
    }

    return (
        <Box sx={{display: 'flex', minHeight: '100vh'}}>
            {loginState === 1 &&
                <LoginDialog open={loginState === 1}/>
            }
            <Box sx={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                <Header/>
                <Box sx={{flex: 1, py: 0, px: 2, bgcolor: '#eaeff1'}}>
                    {is_mobile &&
                        <Grid container justifyContent="center" sx={{pt: 8}}>
                            <img className="site-logo-mobile" src={site_logo} alt=""/>
                        </Grid>
                    }
                    <Grid sx={{pt: is_mobile ? 0 : 10, pb: {xl: 10, lg: 10, md: 10, sm: 6, xs: 6}}}>
                        <h1 className="title" style={{'padding': 0, 'margin': 0}}>据意查句</h1>
                    </Grid>
                    {loginState !== 2 &&
                        <Grid>
                            <div className="please-login" onClick={() => {
                                setLoginState(1)
                            }}>微信登录后才能进行查询，点此登录
                            </div>
                        </Grid>
                    }
                    <Grid container justifyContent="center" id="search-grid" sx={{pt: 2}}>
                        <SearchInput/>
                    </Grid>
                    <Grid container justifyContent="center">
                        <Grid id="quotes-view" sx={{width: {xl: "80%", lg: "80%", md: "80%", sm: "100%", xs: "100%"}}}>
                            <div className=""></div>
                        </Grid>
                        <Grid sx={{
                            width: {xl: "60%", lg: "60%", md: "60%", sm: "90%", xs: "90%"}, pt: {xl: "4em", lg: "4em", md: "4em", sm: "1em", xs: "1em"}
                        }} style={{display: 'block'}} id='use-guide-grid'>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    🎯 &nbsp;<strong className="use-guide-title">WantQuotes可以做什么？</strong> <br/>
                                    {/*<Typography>Accordion 1</Typography>*/}
                                </AccordionSummary>
                                <AccordionDetails sx={{m: 0, pt: 0}}>
                                    <div className="use-guide-detail">
                                        &emsp;&emsp;WantQuotes可以根据你输入描述的意思来查找相应的名言名句，例如输入“树立远大志向”，可以查到歌德的名言“人生之要事在于确立伟大的目标与实现这目标的决心”（现代类名句）、刘禹锡的诗句“少年负志气，信道不从时。”（古诗文类名句）、“人起心发，树起根发”（熟语类名句）等。<br/>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    🔮 &nbsp;<strong className="use-guide-title"> 能查哪些类型的名句？</strong> <br/>
                                    {/*<Typography>Accordion 1</Typography>*/}
                                </AccordionSummary>
                                <AccordionDetails sx={{m: 0, pt: 0}}>
                                    <div className="use-guide-detail">
                                        &emsp;&emsp;目前包含4大类13小类的名句：<br/>
                                        <ul className="type-description">
                                            <li><strong>现代</strong>大类：对应现代汉语名言名句，下分<strong>名言</strong>（指严肃经典名人名言）、<strong>佳句</strong>（包括当代文学、网文、现代诗歌、歌词等中的名句）、<strong>网络</strong>（来自网络的句子）、<strong>台词</strong>（包括影视剧、动漫、综艺等的经典台词）；</li>
                                            <li><strong>古诗文</strong>大类：对应古代名言名句，包括来自古诗词、文言文等的名句；</li>
                                            <li style={{alignItems: 'top'}}><strong>熟语</strong>大类：包括谚语、俗语、惯用语三个小类<TriggersTooltips
                                                hoverOpen={true}
                                                trigger={
                                                    <Stack display={'inline-block'} direction='row' sx={{alignItems: 'top'}}><QuestionMarkIcon className="proverb-question-mark"/></Stack>
                                                }
                                                tip={<Typography className='proverb-answer' sx={{pl: 2}}>
                                                    <li><strong>谚语</strong>：在民间流传的固定语句，用简单通俗的话反映出深刻的道理。如“风后暖，雪后寒”，“三个臭皮匠，赛过诸葛亮”，“三百六十行，行行出状元”。</li>

                                                    <li><strong>俗语</strong>：通俗并广泛流行的定型的语句，简练而形象化，大多数是劳动人民创造出来的，反映人民的生活经验和愿望，如“天下无难事，只怕有心人”。也叫俗话。</li>

                                                    <li><strong>惯用语</strong>：熟语的一种，常以口语色彩较浓的固定词组表达一个完整的意思，多用其比喻意义，如“开夜车、扯后腿、卖关子”等。</li>

                                                    <div style={{'text-align': 'right'}}>——解释出自《现代汉语词典》第7版</div>
                                                </Typography>}/>；
                                            </li>
                                            <li><strong>歇后语</strong>大类：对应歇后语。</li>
                                        </ul>
                                    </div>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    📌 &nbsp;<strong className="use-guide-title"> 其他使用说明？</strong> <br/>
                                </AccordionSummary>
                                <AccordionDetails sx={{m: 0, pt: 0}}>
                                    <div className="use-guide-detail">
                                        <ul>
                                            <li>目前WantQuotes仍为测试版本，可能会出现维护、停机更新等不稳定的情况，但我们会尽量减少这样的情况发生；</li>
                                            <li>一般而言排序靠前的名句越相关，但AI模型计算可能有误，导致不相关的名句排在前面；</li>
                                            <li>名句收集自多个来源，尽管我们采取了一系列方法对名句信息进行校对和处理，但错误仍不可避免——名句的内容、作者、作品、分类都有可能出错，也可能会出现内容重复、敏感的名句；</li>
                                            <li>更多的反馈可以让WantQuotes变得更好！你可以给语义相关的名句点赞👍、反馈名句的错误，也可以在网站右上角点击“反馈”提出你的意见和建议。</li>
                                        </ul>
                                    </div>
                                </AccordionDetails>
                            </Accordion>


                        </Grid>
                    </Grid>
                </Box>
                <Stack component="footer" sx={{p: 2, bgcolor: '#eaeff1', alignItems: 'center'}}>
                    <Typography variant="body2" color="text.secondary" textAlign='center'>
                        {'Copyright © '}
                        <Link color="inherit" href="http://www.thunlp.org/">
                            THUNLP
                        </Link>{' '}
                        {new Date().getFullYear()}
                        {is_mobile ?

                            <>
                                <br/><Link sx={{ml: 0}} underline='hover' color="inherit" href="https://beian.miit.gov.cn/">
                                京ICP备2022002415号-5
                            </Link>
                            </> :
                            <Link sx={{ml: 3}} underline='hover' color="inherit" href="https://beian.miit.gov.cn/">
                                京ICP备2022002415号-5
                            </Link>

                        }
                    </Typography>
                </Stack>
            </Box>
        </Box>
    );
}